import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

import useLocalStorage from 'hooks/useLocalStorageSetting';
import useStableRowCount from './useStableRowCount';

import GridToolbar from './GridToolbar';
import getColumns from './config/columns';
import {
	slotIcons,
	slotProps,
	sortingProps,
	localeText,
} from './config/gridConfigs';

const muiRowHeights = {
	dense: 32,
	standard: 52,
	comfortable: 62,
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
const StyledDataGrid = styled(DataGrid)`
	border: none;

	.MuiDataGrid-columnHeaderTitle {
		white-space: break-spaces;
		text-align: center;
		font-weight: bold;
	}

	.MuiDataGrid-columnHeader,
	.MuiDataGrid-cell {
		border-color: ${({ theme }) => theme.palette.background.paper};
	}

	// needed to not have the grid jump around when loading
	.MuiDataGrid-overlayWrapper,
	.MuiDataGrid-virtualScrollerContent {
		min-height: ${({ $pagesize, $rowheight }) => $pagesize * $rowheight}px;
	}
`;

export default function CompaniesTable({
	loading,
	companies,
	tasks,
	totalCompaniesCount,
	paginationModel,
	setPaginationModel,
	setNewOrder,
}) {
	// Use local storage to store the selected template
	const { value: selectedTemplate, setValue: setSelectedTemplate } =
		useLocalStorage('companiesArchiveTemplate', null);

	// State used to set the minHeight of the grid for skeleton loading
	const [rowHeight, setRowHeight] = useState(muiRowHeights.standard);

	// Use custom hook to get stable row count (to avoid issues when changing pages, sorting or filtering)
	const rowCount = useStableRowCount({
		totalCount: totalCompaniesCount,
		pageSize: paginationModel.pageSize,
	});

	// Update the order state (used by the query) when the sort model changes
	const handleSortModelChange = useCallback(
		sortModel => {
			const nameSort = sortModel[0]?.sort;
			if (!nameSort) return;

			switch (nameSort) {
				case 'asc':
					setNewOrder(1);
					break;
				case 'desc':
					setNewOrder(0);
					break;
				default:
					setNewOrder(1);
					break;
			}
		},
		[setNewOrder]
	);

	// Save the selected visibility model to local storage when it changes
	const handleColumnVisibilityModelChange = useCallback(
		visibilityModel => {
			setSelectedTemplate({
				label: 'Egendefinert',
				value: visibilityModel,
			});
		},
		[setSelectedTemplate]
	);

	/**  Define props for the DataGrid component below */

	// Get default columns and add task columns
	const columns = useMemo(() => getColumns(tasks), [tasks]);
	// Order the columns based on the selected template
	const orderedColumns = useMemo(() => {
		if (
			!selectedTemplate?.value ||
			selectedTemplate.label === 'Egendefinert'
		)
			return columns;

		const visibleColumns = [];
		const hiddenColumns = [];

		const templateKeys = Object.keys(selectedTemplate.value);
		templateKeys.forEach(key => {
			const col = columns.find(c => c.field === key);
			if (col) {
				if (selectedTemplate.value[key]) {
					visibleColumns.push(col);
				} else {
					hiddenColumns.push(col);
				}
			}
		});

		return [...visibleColumns, ...hiddenColumns];
	}, [selectedTemplate, columns]);

	const contentProps = {
		loading,
		columns: orderedColumns,
		rows: companies,
		getRowId: row => row._id,
	};

	const columnVisibilityProps = {
		columnVisibilityModel: selectedTemplate?.value || {},
		onColumnVisibilityModelChange: handleColumnVisibilityModelChange,
	};

	const paginationProps = {
		paginationMode: 'server',
		paginationModel,
		onPaginationModelChange: setPaginationModel,
		rowCount,
		pageSizeOptions: [15, 50, 100],
	};

	const toolbarProps = {
		slots: {
			toolbar: () => (
				<GridToolbar
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					columns={columns}
				/>
			),
			...slotIcons,
		},
		slotProps: slotProps,
	};

	const stylingProps = {
		onDensityChange: density => setRowHeight(muiRowHeights[density]),
		$pagesize: paginationModel.pageSize,
		$rowheight: rowHeight,
		showCellVerticalBorder: true,
	};

	const otherProps = {
		localeText: localeText,
		disableRowSelectionOnClick: true,
		disableColumnMenu: true,
	};

	sortingProps.onSortModelChange = handleSortModelChange;

	return (
		<Wrapper>
			<StyledDataGrid
				{...contentProps}
				{...columnVisibilityProps}
				{...paginationProps}
				{...sortingProps}
				{...toolbarProps}
				{...stylingProps}
				{...otherProps}
			/>
		</Wrapper>
	);
}
