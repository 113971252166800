import { Fragment } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { getRoleLabel } from 'server/libs/companyRoles';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';
import { Card, CardContent } from 'components/GridCardLayout';

import { ADD_PROFF_DATA_DEVIATION_EXCEPTION } from 'api/mutations/tenantMutations';
import {
	InfoSection,
	InfoTitle,
	InfoLineWrapper,
	InfoLine,
	InfoValue,
	Button,
} from 'web/components/panels/PanelItems';
import UpdateProffDataButton from './company/UpdateProffDataButton';

const Wrapper = styled.div`
	margin-bottom: 20px;
`;

const StyledList = styled(List)`
	padding: 0;
	margin: 0;

	a {
		color: inherit;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 16px;
	padding: 0 0 32px;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 1600px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

const ErrorTextMessage = styled.span`
	color: ${p => p.theme.palette.status.error};
	font-weight: 700;
`;

const UserCreateButton = styled.button`
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;
	transition: color 150ms;

	&:hover {
		color: ${p => p.theme.palette.primary.main};
	}
`;

const ToLower = styled.span`
	text-transform: lowercase;
`;

export default function ProffDataDeviations({
	companyId,
	deviations,
	setCreateUserModal,
}) {
	if (!deviations.hasDeviations) {
		return (
			<Wrapper>
				<Card>
					<CardContent>Bedriften har ingen avvik!</CardContent>
				</Card>
			</Wrapper>
		);
	}

	return (
		<>
			{deviations.missingProffData && (
				<ErrorMessage
					title="Mangler data fra Proff!"
					errors={
						'Data fra proff.no mangler! Klikk på knappen for å hente siste data fra proff.no.'
					}
				/>
			)}

			<Grid>
				{deviations.oldProffData && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>Utdatert data fra Proff</InfoTitle>

								<div>
									Data fra proff.no har ikke blitt på over 6
									måneder.
									<UpdateProffDataButton
										companyId={companyId}
										hasProffData={true}
									/>
								</div>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyName && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Navn er registrert i Proff med:
								</InfoTitle>

								<div>{deviations.companyName}</div>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyAuditorsNotInProff && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Revisorfirma ikke registert i Proff:
								</InfoTitle>
								<StyledList>
									{deviations.companyAuditorsNotInProff.map(
										auditor => (
											<Fragment
												key={`company-auditor-${auditor._id}`}
											>
												<ListItem>
													<CompanyAuditorsNotInProff
														companyId={companyId}
														auditor={auditor}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.proffUsersNotInCompany && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Personer registrert i Proff:
								</InfoTitle>

								<StyledList>
									{deviations.proffUsersNotInCompany.map(
										user => (
											<Fragment
												key={`proff-user-${user.personId}-${user.role}`}
											>
												<ListItem>
													<ProffUsersNotInCompany
														companyId={companyId}
														user={user}
														onUserCreate={
															setCreateUserModal
														}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyUsersNotInProff && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Personer ikke registrert i Proff:
								</InfoTitle>

								<StyledList>
									{deviations.companyUsersNotInProff.map(
										user => (
											<Fragment
												key={`company-user-${user._id}-${user.role}`}
											>
												<ListItem>
													<CompanyUsersNotInProff
														companyId={companyId}
														user={user}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.proffOwnersNotInCompany && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Reelle rettighetshavere registrert i Proff:
								</InfoTitle>

								<StyledList>
									{deviations.proffOwnersNotInCompany.map(
										user => (
											<Fragment
												key={`proff-owner-${user.name}`}
											>
												<ListItem>
													<ProffOwnersNotInCompany
														user={user}
														companyId={companyId}
														onUserCreate={
															setCreateUserModal
														}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}

				{deviations.companyOwnersNotInProff && (
					<InfoSection>
						<Card type="warning">
							<CardContent>
								<InfoTitle>
									Reelle rettighetshavere ikke registrert i
									Proff:
								</InfoTitle>

								<StyledList>
									{deviations.companyOwnersNotInProff.map(
										user => (
											<Fragment
												key={`company-owner-${user._id}`}
											>
												<ListItem>
													<CompanyOwnerNotInProff
														companyId={companyId}
														user={user}
													/>
												</ListItem>
												<Divider component="li" />
											</Fragment>
										)
									)}
								</StyledList>
							</CardContent>
						</Card>
					</InfoSection>
				)}
			</Grid>
		</>
	);
}

function CompanyAuditorsNotInProff({ companyId, auditor }) {
	const { notify } = useSnackbar();

	const [addProffDataDeviation, { data, loading, error }] = useMutation(
		ADD_PROFF_DATA_DEVIATION_EXCEPTION,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	if (data && data.updated) return null;

	return (
		<InfoLineWrapper>
			<InfoLine>
				<PersonIcon />

				<InfoValue>
					<div>
						{auditor?.auditingCompany?.name ? (
							<span>
								{auditor.name} ({auditor.auditingCompany.name})
								skal ikke være revisor.
							</span>
						) : (
							<span>
								{auditor.name} mangler tilknyttet revisorfirma
							</span>
						)}
					</div>

					{error && (
						<ErrorTextMessage>{error.toString()}</ErrorTextMessage>
					)}
				</InfoValue>
			</InfoLine>

			<Button
				disabled={loading}
				onClick={async () => {
					const {
						data: { archived },
					} = await addProffDataDeviation({
						variables: {
							_id: companyId,
							exception: {
								type: 'COMPANY_AUDITOR_NOT_IN_PROFF',
								name: auditor.name,
								_id: auditor._id,
							},
						},
					});

					if (archived) {
						notify('Avviket ble arkivert!');
					}
				}}
			>
				Arkiver
			</Button>
		</InfoLineWrapper>
	);
}

function ProffUsersNotInCompany({ companyId, user, onUserCreate }) {
	const { notify } = useSnackbar();

	const [addProffDataDeviation, { data, loading, error }] = useMutation(
		ADD_PROFF_DATA_DEVIATION_EXCEPTION,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	if (data && data.updated) return null;

	return (
		<InfoLineWrapper>
			<InfoLine>
				{user.isRegistered ? (
					<PersonIcon />
				) : (
					<UserCreateButton onClick={() => onUserCreate(user)}>
						<PersonAddIcon />
					</UserCreateButton>
				)}

				<InfoValue>
					<div>
						{user.name} skal være <ToLower>{user.title}</ToLower>.
					</div>

					{error && <ErrorMessage>{error.toString()}</ErrorMessage>}
				</InfoValue>
			</InfoLine>

			<Button
				disabled={loading}
				onClick={async () => {
					const {
						data: { archived },
					} = await addProffDataDeviation({
						variables: {
							_id: companyId,
							exception: {
								type: 'PROFF_USER_NOT_IN_COMPANY',
								name: user.name,
								role: user.role,
							},
						},
					});

					if (archived) {
						notify('Avviket ble arkivert!');
					}
				}}
			>
				Arkiver
			</Button>
		</InfoLineWrapper>
	);
}

function CompanyUsersNotInProff({ companyId, user }) {
	const { notify } = useSnackbar();

	const [addProffDataDeviation, { data, loading, error }] = useMutation(
		ADD_PROFF_DATA_DEVIATION_EXCEPTION,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	if (data && data.updated) return null;

	return (
		<InfoLineWrapper>
			<InfoLine>
				<PersonIcon />

				<InfoValue>
					<div>
						{user.name} skal ikke være{' '}
						<ToLower>{getRoleLabel(user.role)}</ToLower>.
					</div>

					{error && <ErrorMessage>{error.toString()}</ErrorMessage>}
				</InfoValue>
			</InfoLine>

			<Button
				disabled={loading}
				onClick={async () => {
					const {
						data: { archived },
					} = await addProffDataDeviation({
						variables: {
							_id: companyId,
							exception: {
								type: 'COMPANY_USER_NOT_IN_PROFF',
								name: user.name,
								role: user.role,
							},
						},
					});

					if (archived) {
						notify('Avviket ble arkivert');
					}
				}}
			>
				Arkiver
			</Button>
		</InfoLineWrapper>
	);
}

function ProffOwnersNotInCompany({ companyId, user, onUserCreate }) {
	const { notify } = useSnackbar();

	const [addProffDataDeviation, { data, loading, error }] = useMutation(
		ADD_PROFF_DATA_DEVIATION_EXCEPTION,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	if (data && data.updated) return null;

	return (
		<InfoLineWrapper>
			<InfoLine>
				{user.isRegistered ? (
					<PersonIcon />
				) : (
					<UserCreateButton
						onClick={() =>
							onUserCreate({
								...user,
								companyOwner: true,
							})
						}
					>
						<PersonAddIcon />
					</UserCreateButton>
				)}

				<InfoValue>
					<div>{user.name} skal være rettighetshaver.</div>

					{error && <ErrorMessage>{error.toString()}</ErrorMessage>}
				</InfoValue>
			</InfoLine>

			<Button
				disabled={loading}
				onClick={async () => {
					const {
						data: { archived },
					} = await addProffDataDeviation({
						variables: {
							_id: companyId,
							exception: {
								type: 'PROFF_OWNER_NOT_IN_COMPANY',
								name: user.name,
							},
						},
					});

					if (archived) {
						notify('Avviket ble arkivert!');
					}
				}}
			>
				Arkiver
			</Button>
		</InfoLineWrapper>
	);
}

function CompanyOwnerNotInProff({ companyId, user }) {
	const { notify } = useSnackbar();

	const [addProffDataDeviation, { data, loading, error }] = useMutation(
		ADD_PROFF_DATA_DEVIATION_EXCEPTION,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	if (data && data.updated) return null;

	return (
		<InfoLineWrapper>
			<InfoLine>
				<PersonIcon />

				<InfoValue>
					<div>{user.name} skal ikke være rettighetshaver.</div>

					{error && <ErrorMessage>{error.toString()}</ErrorMessage>}
				</InfoValue>
			</InfoLine>

			<Button
				disabled={loading}
				onClick={async () => {
					const {
						data: { archived },
					} = await addProffDataDeviation({
						variables: {
							_id: companyId,
							exception: {
								type: 'COMPANY_OWNER_NOT_IN_PROFF',
								name: user.name,
							},
						},
					});

					if (archived) {
						notify('Avviket ble arkivert!');
					}
				}}
			>
				Arkiver
			</Button>
		</InfoLineWrapper>
	);
}
